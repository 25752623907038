import React, { Component } from 'react';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Header from '../Header';
import ProfileMain from './ProfileMain.js';
import Footer from '../Footer';

class Profile extends Component {
    render() {
        return (
            <div>
                <ScrollToTopOnMount />
                <Header currentPath={this.props.location.pathname} />
                <ProfileMain />
                <Footer />
            </div>
        );
    }
}

export default Profile;