import React, { Component } from 'react';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Header from '../Header';
import DonationMainBefore from './DonationMainBefore';
import DonationMainAfter from './DonationMainAfter';
import Footer from '../Footer';

class Donation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: (
        <DonationMainBefore
          sentDone={() => {
            this.emailSent();
          }}
        />
      )
    };

    this.emailSent = this.emailSent.bind(this);
  }

  emailSent() {
    this.setState({
      form: <DonationMainAfter />
    });
  }

  render() {
    return (
      <div>
        <ScrollToTopOnMount />
        <Header currentPath={this.props.location.pathname} />
        {this.state.form}
        <Footer />
      </div>
    );
  }
}

export default Donation;
