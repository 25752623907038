import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as emailjs from 'emailjs-com';
import {
  Button,
  FormFeedback,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import '../CSS/DonationMain.css';
import '../CSS/Common.css';

class DonationMainBefore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      postalcode: '',
      address: '',
      message: ''
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    const { name, email, postalcode, address, message } = this.state;
    let templateParams = {
      name: name,
      from_mail: email,
      to_name: 'masahiro.nakamura.1992@gmail.com',
      subject: '寄付に関するお問い合わせ',
      postalcode: postalcode,
      address: address,
      message_html: message
    };
    emailjs.send(
      'gmail',
      'template_3wyput6Z_donate',
      templateParams,
      'user_V65CMeiG7X1Ua6tC1dTVo'
    );
    this.resetForm();
  }

  resetForm() {
    this.setState({
      name: '',
      email: '',
      postalcode: '',
      address: '',
      message: ''
    });
    return this.props.sentDone();
  }

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };

  render() {
    return (
      <div>
        <div className='Donation-contents'>
          <h1 className='Donation-header-h1'>ご支援</h1>
          <section>
            <h2>ご寄付に関するお願い</h2>
            <div className='red left'>
              <ul className='Donation-alert'>
                <li></li>
                <li>
                  ※
                  ご連絡先情報を入力していただくと、活動資金（カンパ）のお振込先が表示されます。
                </li>
                <li>
                  ※
                  公職選挙法上、お名前、ご連絡先を必ずお伝えいただく必要があります。
                </li>
                <li>※ 企業献金は公職選挙法上、禁止されております。</li>
              </ul>
            </div>
            <h3>ご連絡先情報</h3>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <FormGroup controlId='formBasicEmail'>
                <Label className='form-title'>メールアドレス</Label>
                <Input
                  type='email'
                  name='email'
                  value={this.state.email}
                  className='text-muted'
                  onChange={this.handleChange.bind(this, 'email')}
                  placeholder=''
                />
              </FormGroup>
              <FormGroup controlId='formBasicName'>
                <Label className='form-title'>お名前</Label>
                <Input
                  type='text'
                  name='name'
                  value={this.state.name}
                  className='text-muted'
                  onChange={this.handleChange.bind(this, 'name')}
                  placeholder=''
                />
              </FormGroup>
              <FormGroup controlId='formBasicName'>
                <Label className='form-title'>郵便番号</Label>
                <Input
                  type='text'
                  name='postalcode'
                  value={this.state.postalcode}
                  className='text-muted'
                  onChange={this.handleChange.bind(this, 'postalcode')}
                  placeholder=''
                />
              </FormGroup>
              <FormGroup controlId='formBasicName'>
                <Label className='form-title'>ご住所</Label>
                <Input
                  type='text'
                  name='address'
                  value={this.state.address}
                  className='text-muted'
                  onChange={this.handleChange.bind(this, 'address')}
                  placeholder=''
                />
              </FormGroup>
              <FormGroup controlId='formBasicMessage'>
                <Label className='form-title'>メッセージ（任意）</Label>
                <Input
                  type='textarea'
                  rows='6'
                  name='message'
                  className='text-muted'
                  value={this.state.message}
                  onChange={this.handleChange.bind(this, 'message')}
                />
              </FormGroup>
              <Button className='form-button' variant='primary' type='submit'>
                送信
              </Button>
            </Form>
          </section>
          <section>
            <h2>ご後援</h2>
            <p>
              下記活動へのご支援は
              <Link to='/contact' className='link'>
                お問い合わせ
              </Link>
              ページよりご連絡ください。
            </p>
            <p>
              ・チラシ配り
              <br />
              ・ポスティング
            </p>
          </section>
        </div>
      </div>
    );
  }
}

DonationMainBefore.propTypes = {
  sentDone: PropTypes.func
};

export default DonationMainBefore;
