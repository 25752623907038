import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as emailjs from 'emailjs-com';
import {
  Button,
  FormFeedback,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import '../CSS/ContactMain.css';

class ContactMainBefore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      subject: '',
      message: ''
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    const { name, email, subject, message } = this.state;
    let templateParams = {
      name: name,
      from_mail: email,
      to_name: 'masahiro.nakamura.1992@gmail.com',
      subject: subject,
      message_html: message
    };
    emailjs.send(
      'gmail',
      'template_3wyput6Z',
      templateParams,
      'user_V65CMeiG7X1Ua6tC1dTVo'
    );
    this.resetForm();
  }

  resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
    return this.props.sentDone();
  }

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };

  render() {
    return (
      <main>
        <div className='Contact-Main'>
          <h1 className='Contact-header-h1'>お問い合わせ</h1>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <FormGroup controlId='formBasicEmail'>
              <Label className='form-title'>メールアドレス</Label>
              <Input
                type='email'
                name='email'
                value={this.state.email}
                className='text-muted'
                onChange={this.handleChange.bind(this, 'email')}
                placeholder=''
              />
            </FormGroup>
            <FormGroup controlId='formBasicName'>
              <Label className='form-title'>お名前</Label>
              <Input
                type='text'
                name='name'
                value={this.state.name}
                className='text-muted'
                onChange={this.handleChange.bind(this, 'name')}
                placeholder=''
              />
            </FormGroup>
            <FormGroup controlId='formBasicSubject'>
              <Label className='form-title'>お問い合わせタイトル</Label>
              <Input
                type='text'
                name='subject'
                className='text-muted'
                value={this.state.subject}
                onChange={this.handleChange.bind(this, 'subject')}
                placeholder=''
              />
            </FormGroup>
            <FormGroup controlId='formBasicMessage'>
              <Label className='form-title'>お問い合わせ内容詳細</Label>
              <Input
                type='textarea'
                rows='6'
                name='message'
                className='text-muted'
                value={this.state.message}
                onChange={this.handleChange.bind(this, 'message')}
              />
            </FormGroup>
            <Button className='form-button' variant='primary' type='submit'>
              送信
            </Button>
          </Form>
        </div>
      </main>
    );
  }
}

ContactMainBefore.propTypes = {
  sentDone: PropTypes.func
};

export default ContactMainBefore;
