import React, { Component } from 'react';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Header from '../Header';
import NewsMain from './NewsMain';
import Footer from '../Footer';

class News extends Component {
    render() {
        return (
            <div>
                <ScrollToTopOnMount />
                <Header currentPath={this.props.location.pathname} />
                <NewsMain />
                <Footer />
            </div>
        );
    }
}

export default News;