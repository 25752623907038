import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Header from '../Header';
import ContactMainBefore from './ContactMainBefore';
import ContactMainAfter from './ContactMainAfter';
import Footer from '../Footer';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: (
        <ContactMainBefore
          sentDone={() => {
            this.emailSent();
          }}
        />
      )
    };

    this.emailSent = this.emailSent.bind(this);
  }

  emailSent() {
    this.setState({
      form: <ContactMainAfter />
    });
  }

  render() {
    return (
      <div>
        <ScrollToTopOnMount />
        <Header currentPath={this.props.location.pathname} />
        {this.state.form}
        <Footer />
      </div>
    );
  }
}

export default Contact;
