import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: 'プロフィール',
      policy: '政策',
      donation: 'ご支援',
      news: 'お知らせ',
      contact: 'お問い合わせ',
      contents: <header />
    };
  }

  componentDidMount() {
    var currentPath = this.props.currentPath;
    switch (currentPath) {
      case '/profile':
        this.setState({
          profile: <span className='currentDirectory'>プロフィール</span>
        });
        break;
      case '/policy':
        this.setState({
          policy: <span className='currentDirectory'>政策</span>
        });
        break;
      case '/donation':
        this.setState({
          donation: <span className='currentDirectory'>ご支援</span>
        });
        break;
      case '/news':
        this.setState({
          donation: <span className='currentDirectory'>お知らせ</span>
        });
        break;
      case '/contact':
        this.setState({
          contact: <span className='currentDirectory'>お問い合わせ</span>
        });
        break;
      default:
        break;
    }

    if (window.innerWidth > 767) {
      this.setState({
        contents: (
          <header className='App-Header'>
            <nav>
              <ul className='Navigation-item'>
                <li>
                  <Link to='/' className='Logo'>
                    <span className='Logo-title'>横須賀市議会議員</span>
                    <span className='Logo-name'>竹岡 力</span>
                  </Link>
                  <span className='Nav-description'>Official Site</span>
                </li>
                <li>
                  <Link to='/profile' className='link'>
                    {this.state.profile}
                  </Link>
                </li>
                <li>
                  <Link to='/policy' className='link'>
                    {this.state.policy}
                  </Link>
                </li>
                <li>
                  <Link to='/donation' className='link'>
                    {this.state.donation}
                  </Link>
                </li>
                <li>
                  <Link to='/news' className='link'>
                    {this.state.news}
                  </Link>
                </li>
                <li>
                  <Link to='/contact' className='link'>
                    {this.state.contact}
                  </Link>
                </li>
              </ul>
            </nav>
          </header>
        )
      });
    } else {
      this.setState({
        contents: (
          <header>
            <div id='nav-drawer'>
              <Link to='/' className='Logo'>
                <span className='Logo-title'>横須賀市議会議員</span>
                <span className='Logo-name'>竹岡 力</span>
              </Link>
              <span className='Nav-description'>Official Site</span>
              <input id='nav-input' type='checkbox' className='nav-unshown' />
              <label id='nav-open' htmlFor='nav-input'>
                <span />
              </label>
              <label
                className='nav-unshown'
                id='nav-close'
                htmlFor='nav-input'
              />
              <div id='nav-content'>
                <nav>
                  <ul className='Navigation-item'>
                    <li>
                      <Link to='/profile' className='link'>
                        {this.state.profile}
                      </Link>
                    </li>
                    <li>
                      <Link to='/policy' className='link'>
                        {this.state.policy}
                      </Link>
                    </li>
                    <li>
                      <Link to='/donation' className='link'>
                        {this.state.donation}
                      </Link>
                    </li>
                    <li>
                      <Link to='/news' className='link'>
                        {this.state.news}
                      </Link>
                    </li>
                    <li>
                      <Link to='/contact' className='link'>
                        {this.state.contact}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>
        )
      });
    }
  }

  render() {
    return <div>{this.state.contents}</div>;
  }
}

export default Header;
