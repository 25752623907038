import React, { Component } from 'react';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Header from '../Header';
import HomeMain from './HomeMain';
import Footer from '../Footer';

class Home extends Component {
    render() {
        return (
            <div>
                <ScrollToTopOnMount />
                <Header currentPath={this.props.location.pathname} />
                <HomeMain />
                <Footer />
            </div>
        );
    }
}

export default Home;