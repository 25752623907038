import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../CSS/DonationMain.css';
import '../CSS/Common.css';

class DonationMainAfter extends Component {
  render() {
    return (
      <div>
        <h1 className='Donation-header-h1'>ご支援</h1>
        <div className='Donation-contents'>
          <section>
            <h2>ご連絡先情報を入力いただき、ありがとうございます。</h2>
            <h3>活動資金（カンパ）のお振込先</h3>
            <p className='p-left'>
              【銀行名】ゆうちょ銀行
              <br />
              【種　別】普通預金
              <br />
              【口座名】竹岡力後援会
              <br />
              【店　番】098
              <br />
              【番　号】3682663
              <br />
            </p>
            <p className='red left'>
              ※ 企業献金は公職選挙法上、禁止されております。
              <br />※ お名前、ご連絡先を必ずお伝えいただく必要があります。
            </p>
          </section>
          <section>
            <h2>ご後援</h2>
            <p>
              下記活動へのご支援は
              <Link to='/contact' className='link'>
                お問い合わせ
              </Link>
              ページよりご連絡ください。
            </p>
            <p>
              ・チラシ配り
              <br />
              ・ポスティング
            </p>
          </section>
        </div>
      </div>
    );
  }
}

export default DonationMainAfter;
