import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import FacebookButtonNormal from './Images/facebook-button-normal.png';
import InstagramButtonNormal from './Images/instagram_icon.png';
import TwitterButtonNormal from './Images/twitter-icon.png';
import MailButtonNormal from './Images/mail-button-normal.png';
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className='Information'>
          <h2>竹岡力 後援会事務所</h2>
          <p>
            〒239-0833
            <br />
            神奈川県横須賀市ハイランド1-45-5
            <br />
            TEL:080-6787-3219
            <br />
            FAX:046-895-2822
          </p>
          <div className='SNS'>
            <a href='https://www.facebook.com/takeokachikara/' className='link'>
              <img
                className='SNS-button-image'
                src={FacebookButtonNormal}
                alt='FB'
              />
            </a>
            <a
              href='https://www.instagram.com/takeoka_chikara/'
              className='Instagram'
            >
              <img
                className='SNS-button-image'
                src={InstagramButtonNormal}
                alt='Twitter'
              />
            </a>
            <a href='https://twitter.com/torakichit' className='link'>
              <img
                className='SNS-button-image'
                src={TwitterButtonNormal}
                alt='E-mail'
              />
            </a>
            <Link to='/contact' className='link'>
              <img
                className='SNS-button-image'
                src={MailButtonNormal}
                alt='E-mail'
              />
            </Link>
            <div
              className='fb-like'
              data-href='https://www.facebook.com/takeokachikara/'
              data-layout='button_count'
              data-action='like'
              data-size='large'
              data-show-faces='false'
              data-share='true'
            />
          </div>
        </div>
        <div className='Copy-rights'>
          ©︎ 2018, Chikara Takeoka, All Rights Reserved.
        </div>
      </footer>
    );
  }
}

export default Footer;
