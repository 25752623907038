import React, { Component } from 'react';
import '../CSS/ContactMain.css';

class ContactMainAfter extends Component {
  render() {
    return (
      <main>
        <div className='Contact-Main'>
          <h1 className='Contact-header-h1'>お問い合わせ</h1>
          <p className='after-sent'>お問い合わせいただき、ありがとうございました。</p>
        </div>
      </main>
    );
  }
}

export default ContactMainAfter;
