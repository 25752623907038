import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './Pages/Home';
import Profile from './Pages/Profile';
import Policy from './Pages/Policy';
import Donation from './Pages/Donation';
import News from './Pages/News';
import Contact from './Pages/Contact';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className='App'>
        <Helmet
          title={'【公式】横須賀市議会議員 竹岡 力 Official Site'}
          meta={[
            {
              property: 'og:image',
              content:
                'https://www.takeokachikara.info/static/media/ogp.767eb031.jpg'
            },
            { property: 'og:url', content: 'https://www.takeokachikara.info/' },
            { property: 'og:type', content: 'article' },
            {
              property: 'og:title',
              content: '【公式】横須賀市議会議員 竹岡力 Official Site'
            },
            {
              property: 'og:description',
              content: '横須賀市議会議員 竹岡力の公式サイトです'
            },
            {
              property: 'og:site_name',
              content: '【公式】横須賀市議会議員 竹岡力 Official Site'
            },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'fragment', content: '!' }
          ]}
        />
        <BrowserRouter>
          <div>
            <Route exact path='/' component={Home} />
            <Route path='/profile' component={Profile} />
            <Route path='/policy' component={Policy} />
            <Route path='/donation' component={Donation} />
            <Route path='/news' component={News} />
            <Route path='/contact' component={Contact} />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
