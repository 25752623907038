import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import '../CSS/Common.css';
import '../CSS/HomeMain.css';

import Portrait from '../Images/portrait-min.jpg';
import Portrait_Mobile from '../Images/portrait_mobile-min.jpg';
import policy01_2023 from '../Images/policy01_2023.jpg';
import policy02_2023 from '../Images/policy02_2023.jpg';
import policy03_2023 from '../Images/policy03_2023.jpg';
import policy04_2023 from '../Images/policy04_2023.jpg';
import policy05_2023 from '../Images/policy05_2023.jpg';
import policy06_2023 from '../Images/policy06_2023.jpg';
import policy07_2023 from '../Images/policy07_2023.jpg';
import policy08_2023 from '../Images/policy08_2023.jpg';
import policy09_2023 from '../Images/policy09_2023.jpg';
import policy10_2023 from '../Images/policy10_2023.jpg';
import policy11_2023 from '../Images/policy11_2023.jpg';
import policy12_2023 from '../Images/policy12_2023.jpg';
import policy13_2023 from '../Images/policy13_2023.jpg';
import policy14_2023 from '../Images/policy14_2023.jpg';
import policy15_2023 from '../Images/policy15_2023.jpg';

const ACCESS_TOKEN = process.env.REACT_APP_FB_TOKEN;

class HomeMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      movies: [],
      fbCreatedTime: [],
      fbMessage: [],
      contents: <div className='Home-Image' />
    };

    this.getFacebook = this.getFacebook.bind(this);
  }

  getFacebook() {
    fetch('https://facebook.github.io/react-native/movies.json')
      .then(response => {
        return response.json();
      })
      .then(responseJson => {
        this.setState({
          movies: responseJson.movies
        });
      });
  }

  componentDidMount() {
    // this.getFacebook();
    if (window.innerWidth > 767) {
      this.setState({
        contents: (
          <div>
            <div className='Home-Image'>
              <div className='Home-Image-PC'>
                <img src={Portrait} />
              </div>
            </div>
          </div>
        )
      });
    } else {
      this.setState({
        contents: (
          <div className='Home-Image'>
            <div className='Home-Image-Mobile'>
              <img src={Portrait_Mobile} />
            </div>
          </div>
        )
      });
    }
  }

  render() {
    return (
      <main>
        <div className='Background-gray'>
          <div>{this.state.contents}</div>
          <div className='Home-Body'>
            <div className='Home-Main'>
              <section>
                <div className='Home-Profile'>
                  <h3>プロフィール</h3>

                  <table>
                    <tbody>
                      <tr>
                        <th>1993</th>
                        <td>ハイランドに生まれ、神明小・中学卒業</td>
                      </tr>
                      <tr>
                        <th>2012</th>
                        <td>県立横須賀高校卒業（64期） 野球部に所属</td>
                      </tr>
                      <tr>
                        <th>2013</th>
                        <td>成蹊大学法学部政治学科入学</td>
                      </tr>
                      <tr>
                        <th>2013 ~ 14</th>
                        <td>市議会議員 嘉山淳平インターン卒了</td>
                      </tr>
                      <tr>
                        <th>2014 ~ 15</th>
                        <td>日本政策学校卒業（6期）</td>
                      </tr>
                      <tr>
                        <th>2014 ~ 16</th>
                        <td>
                          学生団体を立ち上げ、「横須賀学生政策コンペ」を主催
                        </td>
                      </tr>
                      <tr>
                        <th>2015 ~ 17</th>
                        <td>ハイランド1丁目自治会役員を最年少で歴任</td>
                      </tr>
                      <tr>
                        <th>2016 ~ 17</th>
                        <td>タウンニュース連載記事「若者目線」寄稿</td>
                      </tr>
                      <tr>
                        <th>2017</th>
                        <td>成蹊大学法学部政治学科卒業</td>
                      </tr>
                      <tr>
                        <th>2017</th>
                        <td>
                          株式会社ベネッセコーポレーション入社
                          <br />
                          教員や学生向けの講演、学校コンサルタントを経験
                        </td>
                      </tr>
                      <tr>
                        <th>2019.04</th>
                        <td>
                          横須賀市議会議員選挙に初出馬
                          <br />
                          3,760票で当選（当時25歳・史上最年少）
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Link to='/profile' className='Contents-detail-button'>
                    詳しくはこちら
                  </Link>
                </div>
              </section>
              <div className='Separator' />
              <section>
                <div className='Home-Policy'>
                  <h3>政策</h3>
                  <h4>育つ力を、横須賀のチカラに。</h4>
                  <p>
                    これまで「教育を変える」提言を続けてきましたが、これからは「教育を軸に横須賀を変える」提言へと昇華させていきたいと思います。私も結婚し子育てをしていく中で、必ずしも直接教育を受けなくても、人は育っていくと実感をしたからです。
                    <br /><br />
                    例えば生まれたての赤ちゃんは、お母さんお父さんへのサポートを手厚くすることで間接的に育つ力を伸ばすことができます。さらに言えば、高校生大学生の地元就職を促進することができれば、街が育つ力を伸ばすこともできます。
                    <br /><br />
                    そんな街の未来をつくる15の政策アイデアを練り上げました。
                    <br /><br />
                    私竹岡力は、横須賀で暮らす人や街自体が成長していくためのチカラになりたいと思います。
                  </p>
                  <ol>
                    <li>
                      <div className='left'>
                      「待機児童ゼロ」実現するまでは一時預かり補助制度を
                        <br />
                      </div>
                      <img src={policy01_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      ひとり親・双子等をもつ家庭への支援拡大
                        <br />
                      </div>
                      <img src={policy02_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      放課後の居場所を全小学校に確保
                        <br />
                      </div>
                      <img src={policy03_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      教員の更なる負担軽減による教育改革
                        <br />
                      </div>
                      <img src={policy04_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      国際都市・横須賀を誰もが英語と親しめる街に
                        <br />
                      </div>
                      <img src={policy05_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      高校の市民優先枠で市民ファーストな学校を
                        <br />
                      </div>
                      <img src={policy06_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      津波に備える避難施設と避難動線を確保
                        <br />
                      </div>
                      <img src={policy07_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      いざという時に機能する避難所づくりを
                        <br />
                      </div>
                      <img src={policy08_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      横須賀の自然を体感できるきっかけづくりを
                        <br />
                      </div>
                      <img src={policy09_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      もっと政治に参加しやすいまちづくりを
                        <br />
                      </div>
                      <img src={policy10_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      地域のスポーツ資源を部活動に
                        <br />
                      </div>
                      <img src={policy11_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      地元就職を促進する仕組みづくり
                        <br />
                      </div>
                      <img src={policy12_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      すべての人に「学び直し」の機会を
                        <br />
                      </div>
                      <img src={policy13_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      お買い物にもっと行きやすい街へ
                        <br />
                      </div>
                      <img src={policy14_2023} />
                    </li>
                    <li>
                      <div className='left'>
                      認知症による行方不明者の捜索手段を強化
                        <br />
                      </div>
                      <img src={policy15_2023} />
                    </li>
                  </ol>
                  <Link to='/policy' className='Contents-detail-button'>
                    詳しくはこちら
                  </Link>
                </div>
              </section>
              <div className='Separator' />
              <section>
                <div className='Home-Donation'>
                  <h2>ご支援について</h2>
                  <h3>ご寄付</h3>
                  <p>私、竹岡へのご寄付はこちらまで</p>
                  <Link to='/donation' className='Contents-detail-button'>
                    ご寄付
                  </Link>
                  <p className='red'>
                    ※企業献金は公職選挙法上、禁止されております。
                    <br />
                    ※お名前、ご連絡先を必ずお伝えいただく必要があります
                  </p>
                  <h3>ご後援</h3>
                  <p>
                    下記活動へのご支援は
                    <Link to='/contact' className='link'>
                      お問い合わせ
                    </Link>
                    ページよりご連絡ください。
                  </p>
                  <ul>
                    <li>チラシ配り</li>
                    <li>ポスティング</li>
                  </ul>
                </div>
              </section>
              <div className='Separator' />
              <section>
                <div className='Footer-Contact'>
                  <h3>お問い合わせ</h3>
                  <p>私、竹岡へお問い合わせはこちらまで</p>
                  <Link to='/donation' className='Contents-detail-button'>
                    お問い合わせ
                  </Link>
                </div>
              </section>
            </div>
            <div className='Home-Side'>
              <div id='fb-root' />
              <div className='fb-contents'>
                <script
                  async
                  defer
                  crossOrigin='anonymous'
                  src='https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.3&appId=446030242562418&autoLogAppEvents=1'
                />
                <div
                  className='fb-page'
                  data-href='https://www.facebook.com/takeokachikara/'
                  data-tabs='timeline'
                  data-width='300'
                  data-height='600'
                  data-small-header='false'
                  data-adapt-container-width='true'
                  data-hide-cover='false'
                  data-show-facepile='true'
                >
                  <blockquote
                    cite='https://www.facebook.com/takeokachikara/'
                    className='fb-xfbml-parse-ignore'
                  >
                    <a href='https://www.facebook.com/takeokachikara/'>
                      竹岡力
                    </a>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='Home-Separator' />
      </main>
    );
  }
}

export default HomeMain;
