import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../CSS/Common.css';
import '../CSS/ProfileMain.css';

import Header from '../Images/header_profile.jpg';
import Youshou from '../Images/youshou.jpg';
import Youchien from '../Images/youchien.jpg';
import Chugaku from '../Images/chugaku.jpg';
import Koukou from '../Images/koukou.jpg';
import Daigaku from '../Images/daigaku.jpg';
import Syakai from '../Images/syakai.jpg';

class ProfileMain extends Component {
  render() {
    return (
      <main>
        <div className='Background-white'>
          <div className='Profile-Image'>
            <img src={Header} />
            <h3>プロフィール</h3>
          </div>
          <div className='Profile-Main'>
            <section>
              <div className='Profile-Profile'>
                <h4>略歴</h4>
                <table>
                  <tbody>
                    <tr>
                      <th>1993</th>
                      <td>ハイランドに生まれ、神明小・中学卒業</td>
                    </tr>
                    <tr>
                      <th>2012</th>
                      <td>県立横須賀高校卒業（64期） 野球部に所属</td>
                    </tr>
                    <tr>
                      <th>2013</th>
                      <td>成蹊大学法学部政治学科入学</td>
                    </tr>
                    <tr>
                      <th>2013 ~ 14</th>
                      <td>市議会議員 嘉山淳平インターン卒了</td>
                    </tr>
                    <tr>
                      <th>2014 ~ 15</th>
                      <td>日本政策学校卒業（6期）</td>
                    </tr>
                    <tr>
                      <th>2014 ~ 16</th>
                      <td>
                        学生団体を立ち上げ、「横須賀学生政策コンペ」を主催
                      </td>
                    </tr>
                    <tr>
                      <th>2015 ~ 17</th>
                      <td>ハイランド1丁目自治会役員を最年少で歴任</td>
                    </tr>
                    <tr>
                      <th>2016 ~ 17</th>
                      <td>タウンニュース連載記事「若者目線」寄稿</td>
                    </tr>
                    <tr>
                      <th>2017</th>
                      <td>成蹊大学法学部政治学科卒業</td>
                    </tr>
                    <tr>
                      <th>2017</th>
                      <td>
                        株式会社ベネッセコーポレーション入社
                        <br />
                        教員や学生向けの講演、学校コンサルタントを経験
                      </td>
                    </tr>
                    <tr>
                      <th>2019.04</th>
                      <td>
                        横須賀市議会議員選挙に初出馬
                        <br />
                        3,760票で当選（当時25歳・史上最年少）
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h4>自己紹介</h4>
                <p>
                  おおらかで大胆なO型。
                  <br />
                  豚の角煮が好物。嫌いな食べ物はなし！　横須賀の美味しいお店を知りたい。
                  <br />
                  体を動かすことが得意。野球好き。
                  <br />
                  絵を描くことが苦手。料理も苦手。
                  <br />
                  旅行に行くなら北海道！
                </p>
                <h5>幼少期</h5>
                <ul>
                  <li>母子家庭の末っ子に生まれる。</li>
                  <li>
                    地域のおじいちゃん、おばあちゃん世代の方々に、親代わりのように可愛がって面倒を見ていただいた。
                  </li>
                  <li>
                    その影響か、地域の運動会や公園清掃など、地域の行事があれば自然と参加するような子供だった。
                  </li>
                  <li>
                    近所にたくさんの「お友達のおばあちゃん」を作っていた。
                  </li>
                  <li>
                    おばあちゃんからもらっていた「ぽたぽた焼き」が今でも好物。
                  </li>
                </ul>
                <img src={Youshou} />
                <h5>幼稚園</h5>
                <ul>
                  <li>
                    南横須賀幼稚園に入園し、4歳の時からヴァイオリンを習う。
                  </li>
                  <li>
                    きっかけは朝の連続テレビ小説「あぐり」のオープニングを聞き、母に「この曲の楽器を弾きたい！」と言ったから。
                  </li>
                  <li>
                    今思えば母は経済的に相当無理をして習わせてくれていたはず。そのくせ今はもう弾けない。。
                  </li>
                </ul>
                <img src={Youchien} />
                <h5>小学校低学年</h5>
                <ul>
                  <li>神明小学校に入学。</li>
                  <li>
                    この頃はすごくシャイな性格で、授業で手をあげることさえすごく嫌だった。
                  </li>
                  <li>
                    その一方、ボールとバットをさわれば途端に活発で無邪気な野球少年に。休み時間や放課後は必ず外で野球をして遊んでいたので、いまだにテレビゲームに触ったことがない。
                  </li>
                </ul>
                <h5>小学校高学年</h5>
                <ul>
                  <li>
                    小学校4年生の頃、横須賀出身の議員である小泉純一郎首相（当時）が、メディアで劇場型政治などと連日注目を浴びていた。
                  </li>
                  <li>
                    元首相の大衆を引き寄せるような話し方、分かりやすさが幼い自分に魅力に映り、「地元にすごい人がいる」と感じたのがきっかけで政治に興味を持つようになる。
                  </li>
                  <li>同級生には将来自分が総理大臣になると豪語していた。</li>
                  <li>
                    この頃から性格に少しずつ変化があり、クラスのリーダーなど人前に立って目立つことに抵抗がなくなる。
                  </li>
                </ul>
                <h5>中学校</h5>
                <ul>
                  <li>
                    野球部に入りピッチャーを務める。坊主頭になり部活に全力で打ち込む一方、憧れだった小泉首相の母校である県立横須賀高校に入学することが目標になる。
                  </li>
                  <li>
                    生徒会長を務めたことで、それまで下手だったスピーチの技術が磨かれた。
                  </li>
                  <li>卒業式の際には泣きながら代表の言葉を述べた。</li>
                </ul>
                <img src={Chugaku} />
                <h5>高校生</h5>
                <ul>
                  <li>
                    目標だった横須賀高校に入学したが、それに満足してしまい、勉強も全くと言っていいほどやらないような生徒だった。
                  </li>
                  <li>
                    よく職員室に呼ばれるような褒められた生徒ではなかったが、その時に自分に寄り添って話を聞いてくれる先生が好きだった。
                  </li>
                  <li>
                    野球には変わらずに全力で取り組んだ。この時培った体力には今でも自信がある。
                  </li>
                  <li>
                    それでも高校最後の体育祭ではカラー団長を務め、3学年をまとめ上げた。
                  </li>
                </ul>
                <img src={Koukou} />
                <h5>浪人生〜大学生</h5>
                <ul>
                  <li>
                    上記のような高校生活を送った結果、見事に大学受験に失敗。浪人時代の苦労は人生初めての大きな挫折となった。
                  </li>
                  <li>
                    一浪して、第一志望ではなかったが、成蹊大学政治学部法学科に入学。
                  </li>
                  <li>
                    高校時代の反省から、４年間を本気で過ごそうと決意。いろいろなことに果敢にチャレンジするようになった。
                  </li>
                  <li>
                    大学では勉強に打ち込み成績優良者となり、学費免除の対象に選ばれるまでになった。
                  </li>
                  <li>
                    政治の中身を知って、興味が湧くようになる。大学一年生の時、自分でも何かできることをやろうと考えた。
                  </li>
                  <li>
                    学外では政治家のインターンやロータリークラブの下部組織に所属。学生の友人よりも、社会人の方との接点が増していった。同時期に地元自治会の役員も経験。地域コミュニティの高齢化や、若い担い手が自分以外にいないことに危機感を覚えた。
                  </li>
                  <li>
                    若い人と政治を繋げるために、学生団体を立ち上げ「横須賀学生政策コンペ」を開催。活動の様子がメディアでも取り上げられるようになった。
                  </li>
                  <li>
                    政策コンペがきっかけとなって学生が輝いていく姿を見て、個人の可能性を高め磨いていく教育の魅力に惹かれる。
                  </li>
                </ul>
                <img src={Daigaku} />
                <h5>社会人〜現在</h5>
                <ul>
                  <li>
                    大学を卒業後、ベネッセコーポレーションに入社。教師になるための教職課程も履修していたが、より多くの子どもたちに影響を与えられる仕事をしたいと思い、民間企業に就職。
                  </li>
                  <li>
                    初任地の北海道に映り、学校担当者として毎日現場を回る。学校への講演や、先生向けの研修会の実施、私立高校では教育目標や年間カリキュラムの提案など、学校経営改革にも携わる。
                  </li>
                  <li>
                    その中で、行政の動きの遅さや、教育委員会側から変えられることが見えてきたこともあり、地元横須賀を教育のまちにしたいという想いが強くなる。
                  </li>
                  <li>
                    思い切って会社に頭を下げ、横須賀に戻って政治活動をスタートさせる。地盤・看板・鞄なしの無所属で立候補し、3,760票を獲得して史上最年少で当選。
                  </li>
                </ul>
                <img src={Syakai} />
              </div>
            </section>
            <div className='Separator' />
            <section>
              <div className='Footer-Contact'>
                <h3>お問い合わせ</h3>
                <p>私、竹岡へお問い合わせはこちらまで</p>
                <Link to='/contact' className='Contents-detail-button'>
                  お問い合わせ
                </Link>
              </div>
            </section>
            <div className='Separator' />
          </div>
        </div>
      </main>
    );
  }
}

export default ProfileMain;
