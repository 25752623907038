import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../CSS/Common.css';
import '../CSS/PolicyMain.css';

import Header from '../Images/header_policy.jpg';
import policy01_2023 from '../Images/policy01_2023.jpg';
import policy02_2023 from '../Images/policy02_2023.jpg';
import policy03_2023 from '../Images/policy03_2023.jpg';
import policy04_2023 from '../Images/policy04_2023.jpg';
import policy05_2023 from '../Images/policy05_2023.jpg';
import policy06_2023 from '../Images/policy06_2023.jpg';
import policy07_2023 from '../Images/policy07_2023.jpg';
import policy08_2023 from '../Images/policy08_2023.jpg';
import policy09_2023 from '../Images/policy09_2023.jpg';
import policy10_2023 from '../Images/policy10_2023.jpg';
import policy11_2023 from '../Images/policy11_2023.jpg';
import policy12_2023 from '../Images/policy12_2023.jpg';
import policy13_2023 from '../Images/policy13_2023.jpg';
import policy14_2023 from '../Images/policy14_2023.jpg';
import policy15_2023 from '../Images/policy15_2023.jpg';

class PolicyMain extends Component {
  render() {
    return (
      <main>
        <div className='Profile-Image'>
          <img src={Header} />
          <h3>政策</h3>
        </div>
        <div className='Policy-Main'>
          <section>
            <div className='Policy-Policy'>
              <h4>育つ力を、横須賀のチカラに。</h4>
              <p className='Lead'>
                これまで「教育を変える」提言を続けてきましたが、これからは「教育を軸に横須賀を変える」提言へと昇華させていきたいと思います。私も結婚し子育てをしていく中で、必ずしも直接教育を受けなくても、人は育っていくと実感をしたからです。
                <br /><br />
                例えば生まれたての赤ちゃんは、お母さんお父さんへのサポートを手厚くすることで間接的に育つ力を伸ばすことができます。さらに言えば、高校生大学生の地元就職を促進することができれば、街が育つ力を伸ばすこともできます。
                <br /><br />
                そんな街の未来をつくる15の政策アイデアを練り上げました。
                <br /><br />
                私竹岡力は、横須賀で暮らす人や街自体が成長していくためのチカラになりたいと思います。
              </p>
              <ol>
                <li>
                  <div className='left'>
                  「待機児童ゼロ」実現するまでは一時預かり補助制度を
                    <br />
                  </div>
                  <img src={policy01_2023} />
                  <p className='Policy-Article'>
                  引き続きゼロという数字にこだわり、現在待機児童となっているお子さんについては、民間の一時預かり事業を利用した際の利用料が、本来の保育料として払う分と同額に収まるよう補助制度を新設します。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  ひとり親・双子等をもつ家庭への支援拡大
                    <br />
                  </div>
                  <img src={policy02_2023} />
                  <p className='Policy-Article'>
                  母子・父子家庭や多胎児をもつ家庭等、困難さを抱えている子育て世帯を明確にし、それぞれに合った支援メニューを拡充します。特に多胎育児にはこれまで以上に焦点を合わせ、補助拡大や上限緩和に取り組みます。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  放課後の居場所を全小学校に確保
                    <br />
                  </div>
                  <img src={policy03_2023} />
                  <p className='Policy-Article'>
                  市内全校で全児童を対象にした放課後の居場所を2年以内に学校の敷地内で確保します。併せて共働き世帯など留守家庭の小学生が利用する学童クラブも、保護者負担を減らし、校舎内で利用できるようにします。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  教員の更なる負担軽減による教育改革
                    <br />
                  </div>
                  <img src={policy04_2023} />
                  <p className='Policy-Article'>
                  4年間で提言はしたものの、実現に至っていない政策もあります。特に管理職のサポート人材の配置や一般教員のサポート人材の増員は、その進捗を随時追っかけ、必要であれば教育委員会に強く迫り実現に漕ぎつけます。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  国際都市・横須賀を誰もが英語と親しめる街に
                    <br />
                  </div>
                  <img src={policy05_2023} />
                  <p className='Policy-Article'>
                  これだけ国際色が豊かな環境をもっともっと街づくりに活かしていきたいと思います。学生だけでなく、市民誰もが気軽に市内居住の外国人の方々と交流できる機会を創出し、英語が自然に溢れる環境を作ります。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  高校の市民優先枠で市民ファーストな学校を
                    <br />
                  </div>
                  <img src={policy06_2023} />
                  <p className='Policy-Article'>
                  唯一の市立高校である横須賀総合高校の生徒は、市外居住の割合が年々高まっており、現在市民は6割未満です。市民の税金は市民に優先して使われるべき、という考えから、入試に一定数の市民優先枠を創設します。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  津波に備える避難施設と避難動線を確保
                    <br />
                  </div>
                  <img src={policy07_2023} />
                  <p className='Policy-Article'>
                  いざ津波が来た際にどの地域に住んでいる方もすぐに逃げられる環境を整えるべきです。まずは高層階のビルを中心に津波避難施設の指定を増やし、加えて迅速に高台へ避難するための分かりやすい誘導標識を設置します。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  いざという時に機能する避難所づくりを
                    <br />
                  </div>
                  <img src={policy08_2023} />
                  <p className='Policy-Article'>
                  過去の災害時の課題を踏まえ、避難所におけるプライバシーの確保のためのパーテーションや、十分な数の簡易個室トイレ等を確保します。また妊産婦や産後間もない母子を安全に支援できる体制整備を促進します。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  横須賀の自然を体感できるきっかけづくりを
                    <br />
                  </div>
                  <img src={policy09_2023} />
                  <p className='Policy-Article'>
                  横須賀が誇る豊かな自然環境が市民にも伝わり切れていません。田植えや稲刈り、生き物観察等の自然の中で過ごせるイベントを、民間団体が主催しやすいよう、広報面を含め行政が後押しする体制を強化します。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  もっと政治に参加しやすいまちづくりを
                    <br />
                  </div>
                  <img src={policy10_2023} />
                  <p className='Policy-Article'>
                  有権者になるまで、選挙に関する有効な教育機会が不足していることが、政治への参加意識が育たない原因だと考えます。選挙管理委員会と学校がもっと連携し、適切なタイミングで主権者教育を実施します。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  地域のスポーツ資源を部活動に
                    <br />
                  </div>
                  <img src={policy11_2023} />
                  <p className='Policy-Article'>
                  各地域の活発なスポーツクラブの力を、部活動と併用できるように行政との連携を強化します。土日の部活動からの移行や、大会のあり方の抜本的な見直しをすることによって、生徒ファーストな仕組みを作ります。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  地元就職を促進する仕組みづくり
                    <br />
                  </div>
                  <img src={policy12_2023} />
                  <p className='Policy-Article'>
                  就職を機に市外に出てしまう方が多いことから、地元企業を知る機会を増やすために、説明会や就職イベントの周知を拡大します。また一度出た方が戻ってきたいと思えるようなUターン転職を促す機会を創出します。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  すべての人に「学び直し」の機会を
                    <br />
                  </div>
                  <img src={policy13_2023} />
                  <p className='Policy-Article'>
                  人生100年時代、何歳からでも学ぼうとする意欲を湧きたてるきっかけが必要です。決して定年後に限らず、どの世代でも興味がもてるように、市民大学の講座の内容や数、見せ方を改革します。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  お買い物にもっと行きやすい街へ
                    <br />
                  </div>
                  <img src={policy14_2023} />
                  <p className='Policy-Article'>
                  横須賀の地理的な特徴や交通網を考えると、今後「買い物難民」と言われる方が増加してしまう懸念があります。この問題解決のため、乗り合いバス等、行政だけでなく民間事業者とも連携した移動サービスを展開します。
                  </p>
                </li>
                <li>
                  <div className='left'>
                  認知症による行方不明者の捜索手段を強化
                    <br />
                  </div>
                  <img src={policy15_2023} />
                  <p className='Policy-Article'>
                  認知症によって自宅に帰れなくなってしまった際に、周囲の捜索体制が命を守れるかどうかを大きく左右します。現在市の公式LINEに登録されている方に周知する方法がありますが、より後半に拡散できる環境を整えます。
                  </p>
                </li>
              </ol>
            </div>
          </section>
          <div className='Separator' />
          <section>
            <div className='Footer-Contact'>
              <h3>お問い合わせ</h3>
              <p>私、竹岡へお問い合わせはこちらまで</p>
              <Link to='/contact' className='Contents-detail-button'>
                お問い合わせ
              </Link>
            </div>
          </section>
          <div className='Separator' />
        </div>
      </main>
    );
  }
}

export default PolicyMain;
