import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../CSS/Common.css';
import '../CSS/NewsMain.css';

import Header from '../Images/header_policy.jpg';

class NewsMain extends Component {
  render() {
    return (
      <main>
        <div className='Profile-Image'>
          <img src={Header} />
          <h3>お知らせ</h3>
        </div>
        <div className='Policy-Main'>
          <section>
            <div className='Policy-Policy'>
              <h4>最新のお知らせ</h4>
              <div className='Blogcard'>
                <h5>
                  新会派「よこすか未来会議」がマニュフェスト対象を受賞しました。
                </h5>
                <p>
                  <a href='http://www.local-manifesto.jp/manifestoaward/docs/2019100300024/?fbclid=IwAR19h6BmaUAQE_DJFraBZxdN52Rp_Q0ZUERh96kW2cnIPOcdL4sj-fN50h8'>
                  第14回マニフェスト大賞優秀賞
                  </a>
                </p>
              </div>
            </div>
          </section>
          <div className='Separator' />
          <section>
            <div className='Footer-Contact'>
              <h3>お問い合わせ</h3>
              <p>私、竹岡へお問い合わせはこちらまで</p>
              <Link to='/contact' className='Contents-detail-button'>
                お問い合わせ
              </Link>
            </div>
          </section>
          <div className='Separator' />
        </div>
      </main>
    );
  }
}

export default NewsMain;
